@font-face {
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}


.social-card {
  background: #F9D34C;
  background-size: contain;
  background-image: url('https://10k.city/assets/svg/line-category-lg.svg');
  background-position: top center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #EEF2F6;
  border: solid 25px #000;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  position: relative;
}

.header {
  width: 1024px;
  /*content: url('images/header.png');*/
  position: absolute;
  top: 0;
}

.footer {
  width: 1024px;
  /*content: url('images/footer.png');*/
  position: absolute;
  bottom: 0;
}

.social-card .logo {
  position: absolute;
  top: 50px;
}

.social-card .title {
  /*background-color: #F7D549;*/
  padding: 2px 2px;
  width: 100%;
  /*opacity: .9;*/
}

.social-card h1 {
  text-align: center;
  margin-left: 100px;
  margin-right: 100px;
  font-size: 2.75vw;
  line-height: 1.2em;
  font-weight: bolder;
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #1B202B;
}

.author {
  position: absolute;
  bottom: 45px;
  font-weight: bold;
}

.author img {
  width: 50px;
  border-radius: 100%;
  margin-bottom: -15px;
  margin-right: 10px;
}